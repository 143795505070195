import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import ThemeLayout from "../../layout/themeLayout";
import Carousel from "../../component/Carousel/Carousel";
import { HuntsCardWidget } from "../../Widgets/Hunts";
import { useHistory } from "react-router";
import { hunts as huntsApi } from "../../api/hunts";
import { useDispatch } from "react-redux";
import InterestClubCarousel from "../../Widgets/InterestClubCarousel";
import { interests as interestsApi } from "../../api/interests";
import { job_types as jobTypesApi } from "../../api/job_types";
import Button from "../../component/Button/Button";
import CreateHunt from "../../Widgets/HuntForm/CreateHunt";
import { Modal } from "react-bootstrap";
import { getFormData } from "../../helpers/utility";
import { toast } from "react-toastify";
import BackButton from "../../component/BackButton/BackButton";
import "./hunting.style.css";
import Spinner from "../../component/Spinner/Spinner";
import InfiniteScroll from "react-infinite-scroller";
import _ from "lodash";
import SlickCarousel from "../../component/SlickCarousel/SlickCarousel";

const Hunting = React.memo((props) => {
    const [hunts, setHunts] = useState([]);
    const pagination = useRef({
        current_page: 0,
    });
    const history = useHistory();
    const dispatch = useDispatch();
    const [interests, setInterests] = useState([]);
    const [jobTypes, setJobTypes] = useState([]);
    const [selectedInterest, setSelectedInterest] = useState(null);
    // const [filters, setFilters] = useState([]);
    const formRef = useRef();
    const postBtn = useRef();
    const [inProgress, setInProgress] = useState(false);
    const loading = useRef(false);

    //load job types
    useEffect(() => {
        dispatch(jobTypesApi())
            .then((response) => {
                setJobTypes(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    //load interests
    useEffect(() => {
        dispatch(interestsApi())
            .then((response) => {
                setInterests(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    const getHunts = (per_page = 5, page_no = 1) => {
        loading.current = true;
        let params = {
            per_page,
            page_no,
        };

        const formData = new FormData(formRef.current);

        let temp = {
            job_title: formData.get("filter_job_title"),
            job_type: formData.get("filter_job_type"),
            min_salary: formData.get("filter_min_salary"),
            max_salary: formData.get("filter_max_salary"),
            interest_id: selectedInterest,
        };

        if (temp.job_title) {
            params.job_title = temp.job_title;
        }

        if (temp.job_type && temp.job_type !== "null") {
            params.job_type = temp.job_type;
        }

        if (temp.min_salary) {
            params.min_salary = temp.min_salary;
        }

        if (temp.max_salary) {
            params.max_salary = temp.max_salary;
        }

        if (temp.interest_id) {
            params.interest_id = temp.interest_id;
        }

        dispatch(
            huntsApi({
                isBackground: false,
                params,
            }),
        )
            .then((response) => {
                loading.current = false;
                if (response.pagination_data.current_page == 1) {
                    pagination.current = response.pagination_data;
                    setHunts(response.data);
                } else {
                    if (response.pagination_data.last_page > pagination.current?.current_page) {
                        pagination.current = response.pagination_data;
                        setHunts(_.uniqBy([...hunts, ...response.data], "id"));
                    }
                }
            })
            .catch((err) => {
                loading.current = false;
                console.log(err);
            });
    };

    // load hunts
    useEffect(() => {
        getHunts();
    }, [dispatch]);

    const onSubmitForm = (e) => {
        e.preventDefault();
        pagination.current = { current_page: 0 };
        setHunts([]);
        getHunts();
    };

    const selectInterest = (id) => {
        if (selectedInterest == id) {
            setSelectedInterest(null);
            pagination.current = { current_page: 0 };
            setHunts([]);
            // getHunts();
        } else {
            setSelectedInterest(id);
            pagination.current = { current_page: 0 };
            setHunts([]);
            // getHunts();
        }
    };

    useEffect(() => {
        setHunts([]);
        getHunts();
    }, [dispatch, selectedInterest]);

    // useEffect(() => {
    //     if (hunts.length == 0) {
    //         getHunts();
    //     }
    // }, [hunts]);

    const renderInterests = useMemo(() => {
        return interests?.map((i) => <InterestClubCarousel interest={i} key={i.id} onClick={() => selectInterest(i.id)} isActive={i.id == selectedInterest} />);
    }, [interests, selectedInterest]);

    const renderHuntsCards = useMemo(() => {
        const renderHuntItems = () => {
            return hunts?.map((h) => (
                <div key={h.id} className="col-lg-4 col-md-4 col-sm-6 d-flex">
                    <HuntsCardWidget hunt={h} />
                </div>
            ));
        };

        const onEndReach = () => {
            getHunts(5, pagination?.current?.current_page + 1);
        };

        return (
            <InfiniteScroll
                pageStart={0}
                loadMore={onEndReach}
                hasMore={hunts?.length < pagination?.current?.total}
                loader={<Spinner key="Loadmore" type="LoadMore" />}
                threshold={50}
                useWindow={true}
            >
                <div className="row">{renderHuntItems()}</div>
            </InfiniteScroll>
        );
    }, [hunts, pagination, selectedInterest]);

    const renderJobTypes = useMemo(() => {
        jobTypes?.map((jt) => (
            <option key={jt.id} value={jt.id}>
                {jt.type_name}
            </option>
        ));
    }, [jobTypes]);

    // modal states and variables
    const [showmodal, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            {/* page loader */}
            <Modal size="lg" id="submit-modal" show={showmodal} onHide={handleClose} backdrop="static">
                <Modal.Header className="p-3 border-bottom">
                    <h4 className="modal-title">Create New Hunt</h4>
                    <Button className="close" htmlType="link" onClick={handleClose}>
                        x
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <CreateHunt />
                </Modal.Body>
            </Modal>

            <ThemeLayout>
                <section>
                    <div className="gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div id="page-contents" className="row merged20">
                                        <div className="col-lg-3">
                                            <aside className="sidebar static right">
                                                <div className="widget stick-widget">
                                                    {/* <Button className="text-muted" htmlType="link" onClick={() => history.goBack()}><i className="icofont-long-arrow-left" /> Go back</Button> */}
                                                    <BackButton />
                                                    <hr />
                                                    <div className="ask-question">
                                                        <Button type="button" onClick={handleShow}>
                                                            Want to engage
                                                        </Button>
                                                    </div>
                                                    <hr />
                                                    <h4 className="widget-title mb-2">Search for participation/engagement</h4>
                                                    <form ref={formRef} className="mt-4" onSubmit={onSubmitForm}>
                                                        <div className="col-12 p-0 mb-3">
                                                            <input type="text" className="form-control" maxLength="150" name="filter_job_title" placeholder="Opportunity Title" />
                                                        </div>
                                                        <div className="col-12 p-0 mb-3">
                                                            <select className="form-control" name="filter_job_type">
                                                                <option value="null">Opportunity Type</option>
                                                                {/* {renderJobTypes} */}
                                                                {jobTypes?.map((jt) => (
                                                                    <option key={jt.id} value={jt.type_name}>
                                                                        {jt.type_name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <h4 className="widget-title mb-2">Salary Range</h4>
                                                        <div className="d-flex mb-3">
                                                            <div className="col-5 p-0">
                                                                <input type="number" className="form-control" name="filter_min_salary" placeholder="MIN" max={999999} />
                                                            </div>
                                                            <div className="col-2 p-0 text-center">
                                                                <span
                                                                    style={{
                                                                        lineHeight: "36px",
                                                                    }}
                                                                >
                                                                    to
                                                                </span>
                                                            </div>
                                                            <div className="col-5 p-0">
                                                                <input className="form-control" type="number" name="filter_max_salary" placeholder="MAX" max={999999} />
                                                            </div>
                                                        </div>
                                                        <Button htmlType="submit" type="button" ref={postBtn} className="form__button">
                                                            Filter Hunts
                                                        </Button>
                                                        <Button
                                                            type="button"
                                                            className="form__button float-right"
                                                            onClick={() => {
                                                                formRef.current.reset();
                                                                pagination.current = {
                                                                    current_page: 0,
                                                                };
                                                                setHunts([]);
                                                                getHunts();
                                                            }}
                                                        >
                                                            Reset
                                                        </Button>

                                                        {/* <Button htmlType="button" onClick={filterData}>Filter</Button> */}
                                                    </form>
                                                </div>
                                            </aside>
                                        </div>
                                        <div className="col-lg-9">
                                            <div className="main-wraper mt-2">
                                                <div className="main-title">Browse Hunt Category</div>
                                                <div className="event-browse category-carousel suggested-clubs">
                                                    <SlickCarousel>{renderInterests}</SlickCarousel>
                                                </div>
                                            </div>
                                            <div className="main-wraper">
                                                <div className="main-title">Hunters at Nearby Businesses</div>
                                                {hunts?.length > 0 && renderHuntsCards}
                                                {hunts?.length == 0 && !loading.current && <div className="text-center">No Hunts Found</div>}

                                                {hunts?.length == 0 && loading.current && <div className="text-center">Loading...</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ThemeLayout>
        </div>
    );
});

export default Hunting;
