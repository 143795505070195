import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import ThemeLayout from "../../layout/themeLayout";
import Carousel from "../../component/Carousel/Carousel";
import { ClubCardWidget } from "../../Widgets/Club";
import { UserClubWidget } from "../../Widgets/Club";
import { useHistory } from "react-router";
import { clubs as clubsApi } from "api/clubs";
import { useSelector, useDispatch } from "react-redux";
import InterestClubCarousel from "../../Widgets/InterestClubCarousel";
import { interests as interestsApi } from "../../api/interests";
import BackButton from "../../component/BackButton/BackButton";
import Spinner from "../../component/Spinner/Spinner";
import InfiniteScroll from "react-infinite-scroller";
import _ from "lodash";
import SlickCarousel from "../../component/SlickCarousel/SlickCarousel";

const Clubs = React.memo((props) => {
    const [clubs, setClubs] = useState([]);
    const pagination = useRef({
        current_page: 0,
    });
    const history = useHistory();
    const userClubs = useSelector((store) => store.user.user.clubs);
    const user_id = useSelector((store) => store.user.user.id);
    const [interests, setInterests] = useState([]);
    const [selectedInterest, setSelectedInterest] = useState(null);
    const [myClubs, setMyClubs] = useState([]);
    const dispatch = useDispatch();
    const loading = useRef(false);

    //load interests
    useEffect(() => {
        dispatch(interestsApi())
            .then((response) => {
                setInterests(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    const getClubs = (per_page = 15, page_no = 1) => {
        loading.current = true;
        let params = {
            per_page,
            page_no,
        };

        if (selectInterest) {
            params.interest_id = selectedInterest;
        }
        dispatch(
            clubsApi({
                isBackground: false,
                params,
            }),
        )
            .then((response) => {
                loading.current = false;
                if (response.success) {
                    if (response.pagination_data.current_page == 1) {
                        pagination.current = response.pagination_data;
                        setClubs(response.data);
                    } else {
                        if (response.pagination_data.last_page > pagination.current?.current_page) {
                            pagination.current = response.pagination_data;
                            setClubs(_.uniqBy([...clubs, ...response.data], "id"));
                        }
                    }
                }
            })
            .catch((err) => {
                loading.current = false;
                console.log(err);
            });
    };

    // load clubs
    useEffect(() => {
        getClubs();
    }, [dispatch]);

    const selectInterest = (id) => {
        if (selectedInterest == id) {
            setSelectedInterest(null);
            pagination.current = { current_page: 0 };
            setClubs([]);
        } else {
            setSelectedInterest(id);
            pagination.current = { current_page: 0 };
            setClubs([]);
        }
    };

    useEffect(() => {
        setClubs([]);
        getClubs();
    }, [dispatch, selectedInterest]);

    useEffect(() => {
        dispatch(
            clubsApi({
                param_path: "my-clubs",
            }),
        )
            .then((response) => {
                if (response.success) {
                    setMyClubs(response.data);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }, [dispatch]);

    const renderInterests = useMemo(() => {
        return interests?.map((i) => <InterestClubCarousel interest={i} key={i.id} onClick={() => selectInterest(i.id)} isActive={i.id == selectedInterest} />);
    }, [interests, selectedInterest]);

    const renderClubCards = useMemo(() => {
        const renderClubItems = () => {
            return clubs?.map((c) => (
                <div key={c.id} className="col-lg-4 col-md-4 col-sm-6">
                    <ClubCardWidget key={c.id} club={c} />
                </div>
            ));
        };

        const onEndReach = () => {
            getClubs(15, pagination?.current?.current_page + 1);
        };

        return (
            <InfiniteScroll
                pageStart={0}
                loadMore={onEndReach}
                hasMore={clubs?.length < pagination?.current?.total}
                loader={<Spinner key="Loadmore" type="LoadMore" />}
                threshold={50}
                useWindow={true}
            >
                <div className="row">{renderClubItems()}</div>
            </InfiniteScroll>
        );
    }, [clubs, pagination, selectedInterest]);
    // const renderClubCards = useMemo(() => {
    //     return clubs?.map((c) => (
    //         <div key={c.id} className="col-lg-4 col-md-4 col-sm-6">
    //             <ClubCardWidget club={c} />
    //         </div>
    //     ));
    // }, [clubs]);
    const renderUserClubs = useMemo(() => {
        return userClubs?.map((uc) => <UserClubWidget key={uc.id} userClub={uc}></UserClubWidget>);
    }, [userClubs]);

    const renderMyClubs = () => {
        return myClubs?.map((uc) => <UserClubWidget key={uc.id} userClub={uc}></UserClubWidget>);
    };

    return (
        <ThemeLayout>
            <section>
                <div className="gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div id="page-contents" className="row merged20">
                                    <div className="col-lg-3">
                                        <BackButton />
                                        {userClubs?.length > 0 && (
                                            <aside className="sidebar static right mt-2">
                                                <div className="widget stick-widget">
                                                    <h4 className="widget-title">
                                                        Joined Clubs{" "}
                                                        <span
                                                            className="float-right small"
                                                            onClick={() => {
                                                                history.push(`/all/joined-clubs`);
                                                            }}
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            See All
                                                        </span>
                                                    </h4>

                                                    <ul className="ak-groups">{renderUserClubs}</ul>
                                                </div>
                                            </aside>
                                        )}
                                        {myClubs?.length > 0 && (
                                            <aside className="sidebar static right mt-2">
                                                <div className="widget stick-widget">
                                                    <h4 className="widget-title">
                                                        My Clubs
                                                        <span
                                                            className="float-right small"
                                                            onClick={() => {
                                                                history.push("/all/my-clubs");
                                                            }}
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            See All
                                                        </span>
                                                    </h4>
                                                    <ul className="ak-groups">{renderMyClubs()}</ul>
                                                </div>
                                            </aside>
                                        )}
                                    </div>
                                    <div className="col-lg-9">
                                        <div className="main-wraper">
                                            <div className="main-title">Browse Clubs</div>
                                            <div className="event-browse category-carousel suggested-clubs">
                                                {/* <Carousel show={interests.length < 4 ? interests.length : 4}>{renderInterests}</Carousel> */}
                                                <SlickCarousel>{renderInterests}</SlickCarousel>
                                            </div>
                                        </div>

                                        <div className="main-wraper">
                                            {clubs.length > 0 && <div className="main-title">Daily Recommendations</div>}
                                            {clubs?.length > 0 && renderClubCards}
                                            {clubs?.length == 0 && !loading.current && <div className="text-center">No Clubs Found</div>}

                                            {clubs?.length == 0 && loading.current && <div className="text-center">Loading...</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ThemeLayout>
    );
});

export default Clubs;
