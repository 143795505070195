import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./categories.module.css";
import { interests as interestsApi } from "../../api/interests";
import RoundImage from "../../component/Image/RoundImage";
import { PLACEHOLDER } from "../../helpers/constant";

const CategoriesWidget = (props) => {
    const [interests, setInterests] = useState([]);
    const dispatch = useDispatch();

    //load interests
    useEffect(() => {
        dispatch(interestsApi())
            .then((response) => {
                setInterests(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    return (
        <div className={`${styles["incldes"]}`}>
            <h4 className="main-title">{props.widgetTitle}</h4>
            <ul className={`${styles["post-categoroes"]} post-categoroes`}>
                {interests?.map((interest) => (
                    <li
                        onClick={() => props.onClick(interest.id)}
                        key={interest.id}
                        className={`${props.activeCategory.includes(interest.id)
                            ? styles["active"]
                            : ""
                            } mr-2`}
                    >
                        <span className={`bg-${interest.color} d-inline-block rounded-circle p-1 `}>
                            <RoundImage src={interest?.image || PLACEHOLDER} size="20px" />
                        </span>
                        {/* <img
                            className={`bg-${interest.color}`}
                            src={interest?.image}
                            style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "100%",
                            }}
                        /> */}
                        {" "}
                        {interest.title}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CategoriesWidget;
