import React from "react";
import RoundImage from "../component/Image/RoundImage";
import { PLACEHOLDER } from "../helpers/constant";

const InterestClubCarousel = (props) => {
    const interest = props.interest;

    return (
        <a
            className={`m-auto room-avatar w-100 ${props.isActive ? "active" : ""}`}
            onClick={props.onClick}
            style={{
                padding: "15px",
            }}
        >
            <span className={`bg-${interest.color} d-inline-block rounded-circle p-1 `}>
                <RoundImage src={interest?.image || PLACEHOLDER} size="20px" />
            </span>
            <h6>{interest.title}</h6>
        </a>
    );
};

export default InterestClubCarousel;
