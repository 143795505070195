import React, { useEffect, useRef, useState } from "react";
import Input from "../Input/Input";

let autocomplete;
let geocoder;

const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;

    document.getElementsByTagName("head")[0].appendChild(script);
};

const handlePlaceSelect = async (updateQuery, setLocation) => {
    setLocation({});
    const addressObject = autocomplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);

    let region = addressObject.address_components
        .reverse()
        .filter((c) => c.types.includes("country") || c.types.includes("administrative_area_level_1") || c.types.includes("administrative_area_level_2"));

    geocoder.geocode({ address: query }, (results, status) => {
        if (status == window.google.maps.GeocoderStatus.OK) {
            let location = results[0].geometry.location;

            setLocation({
                latitude: location.lat(),
                longitude: location.lng(),
                country: region[0].long_name,
                country_iso: region[0].short_name,
                state_name: region[1].long_name,
                state: region[1].short_name,
                city: region[2].long_name,
                region: region
                    .reverse()
                    .map((r) => r.long_name)
                    .join(", "),
            });
        }
    });
};

const defaultPlaceSelect = async (setLocation, query) => {
    setLocation({});

    let { results } = await geocoder.geocode({ address: query });

    let region = results[0].address_components
        .reverse()
        .filter((c) => c.types.includes("country") || c.types.includes("administrative_area_level_1") || c.types.includes("administrative_area_level_2"));
    let location = results[0].geometry.location;

    setLocation({
        latitude: location.lat(),
        longitude: location.lng(),
        country: region[0].long_name,
        country_iso: region[0].short_name,
        state_name: region[1].long_name,
        state: region[1].short_name,
        city: region[2].long_name,
        region: region
            .reverse()
            .map((r) => r.long_name)
            .join(", "),
    });
};

const handleScriptLoad = (updateQuery, setLocation, acRef, query) => {
    autocomplete = new window.google.maps.places.Autocomplete(acRef.current);

    geocoder = new window.google.maps.Geocoder();

    autocomplete.setFields(["address_components", "formatted_address"]);
    autocomplete.addListener("place_changed", () => handlePlaceSelect(updateQuery, setLocation));

    if (query) {
        defaultPlaceSelect(setLocation, query);
    }
};

const GoogleAutocompleteInput = (props) => {
    const locationName = props.location_name || "";
    const [query, setQuery] = useState("");
    const [location, setLocation] = useState({});
    const autoCompleteRef = useRef(null);

    useEffect(() => {
        if (locationName) {
            setQuery(locationName);
        }

        loadScript(`https://maps.googleapis.com/maps/api/js?key=${window.GOOGLE_MAPS_KEY}&libraries=places`, () => handleScriptLoad(setQuery, setLocation, autoCompleteRef, locationName));

        return () => {
            let ps = document.querySelectorAll("script");

            if (ps.length > 0) {
                ps.forEach((script) => {
                    if (script.src.includes("maps.googleapis.com")) {
                        script.remove();
                    }
                });
            }
        };
    }, [locationName]);

    useEffect(() => {
        props.returnLocation(location);
    }, [location]);

    const view = () => {
        return (
            <>
                {/* <Input className={`uk-input`} name="business" defaultValue={hunt?.business} styleType="floating" placeholder="Business Name" /> */}
                <div className="col-md-6">
                    <Input className={`form-control`} name="location_name" styleType="floating" placeholder="Location" onChange={(e) => setQuery(e.target.value)} ref={autoCompleteRef} value={query} />
                </div>
                <div className="col-md-6">
                    <Input className="form-control d-none" name="latitude" type="text" value={location.latitude} />
                    <Input className="form-control d-none" name="longitude" type="text" value={location.longitude} />
                    {/* <label className="form-label mt-3">Region</label> */}
                    <Input className={`form-control`} name="region" styleType="floating" placeholder="Region" type="text" value={location.region} readOnly />
                    {props.children}
                </div>
            </>
        );
    };
    return view();
};

export default GoogleAutocompleteInput;

const FormInput = (props) => {
    const { name, value, type, className } = props;

    return <input className={className} name={name} type={type} defaultValue={value} readOnly={!!props.readOnly} />;
};
