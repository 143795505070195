import React, { useCallback, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PLACEHOLDER } from "helpers/constant";
import { Link, useHistory } from "react-router-dom";
import RoundImage from "../../component/Image/RoundImage";
import SquareImage from "../../component/Image/SquareImage";
import Button from "../../component/Button/Button";
import Input from "../../component/Input/Input";
import Progress from "../../component/Progress/Progress";
import SelectDropdown from "../../component/SelectDropdown/SelectDropdown";
import styles from "./createhunt.module.css";

import Joi from "joi";
import { getFormData } from "helpers/utility";
import { hunts, hunts as huntsApi } from "../../api/hunts";
import { toast } from "react-toastify";
import { setErrorMessages, validateRequired } from "../../helpers/validationMessages";
import Error from "../../component/Error/Error";
import CategoriesWidget from "../Categories/Categories";
import GoogleAutocompleteInput from "../../component/GoogleMaps/AutoCompleteInput";
import { job_types as jobTypesApi } from "../../api/job_types";
import { pay_types as payTypesApi } from "../../api/pay_types";

const CreateHunt = React.memo((props) => {
    const [activeCategory, setCategory] = useState("");
    const formRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const progressRef = useRef();
    const postBtn = useRef();
    const [selectedJobType, setSelectedJobType] = useState(1);
    const [selectedPayType, setSelectedPayType] = useState(1);
    const [selectedStatus, setSelectedStatus] = useState("open");

    const [cover, setCoverImage] = useState();
    const [errors, setErrors] = useState({});

    const { hunt_id } = props;
    const [hunt, setHunt] = useState({});
    const [imageSrc, setImageSrc] = useState();
    const [validateImage, setValidateImage] = useState(1);
    const auth_user = useSelector((state) => state.user.user);
    const [location, setLocation] = useState({});
    const [jobtypes, setjobtypes] = useState([]);
    const [paytypes, setpaytypes] = useState([]);

    const status = [
        {
            id: "open",
            name: "open",
        },
        {
            id: "close",
            name: "closed",
        },
    ];

    const onCoverUpload = (e) => {
        setCoverImage(e.target.files[0]);
        setImageSrc(URL.createObjectURL(e.target.files[0]));
    };
    const selectJobType = (v) => {
        setSelectedJobType(v);
    };
    const selectPayType = (v) => {
        setSelectedPayType(v);
    };
    const selectStatus = (v) => {
        setSelectedStatus(v);
    };
    const onSubmitForm = async (e) => {
        e.preventDefault();

        const formData = new FormData(formRef.current);

        let data = {
            validate_image: validateImage,
            job_type_id: selectedJobType,
            pay_type_id: selectedPayType,
            business: formData.get("business"),
            job_title: formData.get("job_title"),
            job_description: formData.get("job_description"),
            min_salary: formData.get("min_salary"),
            max_salary: formData.get("max_salary"),
            region: formData.get("region"),
            latitude: formData.get("latitude"),
            longitude: formData.get("longitude"),
            location_name: formData.get("location_name"),
            status: selectedStatus,
            interest_id: activeCategory,
        };

        if (validateImage == 1) {
            data.image = cover;
        }

        const { error } = newHuntSchema.validate(data, { abortEarly: false });

        if (error) {
            console.log(error.details);
            const t = setErrorMessages(error);

            return setErrors(t);
        }

        const params = {
            data,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            method: "POST",
            onUploadProgress: (progressEvent) => {
                progressRef.current?.setProgress((progressEvent.loaded / progressEvent.total) * 100);
            },
        };

        if (hunt_id) {
            delete params.data.validate_image;
            params.data._method = "PATCH";
            params.param_path = `${hunt_id}`;
        }

        params.data = getFormData(params.data);

        postBtn.current.showLoader(true);
        try {
            const response = await dispatch(huntsApi(params));

            if (response.success) {
                toast.success(response.message);
                history.push(`/hunt/${response.data?.id}`);
            }
        } catch (e) {
            const t = setErrorMessages(e, true);
            setErrors(t);
        }
        progressRef.current?.setProgress(0);
        postBtn.current?.showLoader(false);
    };

    useEffect(() => {
        dispatch(jobTypesApi())
            .then((response) => {
                if (response.success) {
                    setjobtypes(
                        response.data.map((t) => {
                            return {
                                id: t.id,
                                name: t.type_name,
                            };
                        }),
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    useEffect(() => {
        dispatch(payTypesApi())
            .then((response) => {
                if (response.success) {
                    setpaytypes(
                        response.data.map((c) => {
                            return {
                                id: c.id,
                                name: c.pay_title,
                            };
                        }),
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    useEffect(() => {
        if (hunt_id) {
            dispatch(hunts({ param_path: hunt_id }))
                .then((response) => {
                    if (response.success) {
                        if (auth_user.id !== response.data.created_by.id) {
                            toast.error("You are not authorized to edit this hunt");
                            history.replace("/");
                            return;
                        }
                        setHunt(response.data);
                        setCategory(response.data.interest_id);
                        setImageSrc(response.data.image);
                        setValidateImage(0);
                        setSelectedJobType(response.data.job_type.id);
                        setSelectedPayType(response.data.pay_type.id);
                        setSelectedStatus(response.data.status);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [dispatch, hunt_id]);

    const removeImage = () => {
        setImageSrc(null);
        setCoverImage(null);
        setValidateImage(1);
    };

    return (
        <div>
            <form ref={formRef} className={`mt-4`} onSubmit={onSubmitForm}>
                <input type="hidden" name="validate_image" value={validateImage} />
                <div className={`row`}>
                    <div className={`col-lg-12`}>
                        <CategoriesWidget onClick={setCategory} activeCategory={[activeCategory]} widgetTitle="Categories" />
                        {errors["interest_id"] && <Error marginTop="-12px" error={errors["interest_id"]} />}
                    </div>
                    <div className={`col-lg-4`}>
                        <div className={`${styles["incldes"]} incldes`}>
                            <h4 className={`main-title`}>Select Job Type</h4>
                            <SelectDropdown onChange={selectJobType} styleType="floating" placeholder="Job Type" options={jobtypes} selected={selectedJobType} />
                        </div>
                        {errors["job_type_id"] && <Error marginTop="-12px" error={errors["job_type_id"]} />}
                    </div>
                    <div className={`col-lg-4`}>
                        <div className={`${styles["incldes"]} incldes`}>
                            <h4 className={`main-title`}>Select Pay Type</h4>
                            <SelectDropdown onChange={selectPayType} styleType="floating" placeholder="Pay Type" options={paytypes} selected={selectedPayType} />
                        </div>
                        {errors["pay_type_id"] && <Error marginTop="-12px" error={errors["pay_type_id"]} />}
                    </div>
                    <div className={`col-lg-4`}>
                        <div className={`${styles["incldes"]} incldes`}>
                            <h4 className={`main-title`}>Status</h4>
                            <SelectDropdown onChange={selectStatus} styleType="floating" placeholder="Status" options={status} selected={selectedStatus} />
                        </div>
                        {errors["status"] && <Error marginTop="-12px" error={errors["status"]} />}
                    </div>
                    <div className={`mt-4 mb-1 col-lg-12`}>
                        <h4 className={`main-title`}>Hunt Description</h4>
                    </div>
                    <div className={`col-lg-6`}>
                        <Input className={`uk-input`} name="business" defaultValue={hunt?.business} styleType="floating" placeholder="Business Name" />
                        {errors["business"] && <Error marginTop="-12px" error={errors["business"]} />}
                    </div>
                    <div className={`col-lg-6`}>
                        <Input className={`uk-input`} name="job_title" defaultValue={hunt?.job_title} styleType="floating" placeholder="Job Title" />
                        {errors["job_title"] && <Error marginTop="-12px" error={errors["job_title"]} />}
                    </div>

                    <div className={`mb-4 col-lg-12`}>
                        <Input name="job_description" defaultValue={hunt?.job_description} styleType="floating-area" placeholder="Jobs Description" />
                        {errors["job_description"] && <Error marginTop="-12px" error={errors["job_description"]} />}
                    </div>
                    <div className={`col-lg-12`}>
                        <h4 className={`main-title`}>Location Details</h4>
                    </div>
                    <GoogleAutocompleteInput name="region" returnLocation={setLocation} location_name={hunt?.location?.location_name}>
                        {(errors["region"] || errors["location_name"] || errors["latitude"] || errors["longitude"]) && (
                            <Error error="There is something wrong with the location. Please try to search the location above and select from the list"></Error>
                        )}
                    </GoogleAutocompleteInput>
                    <div className={`col-lg-12`}>
                        <h4 className={`main-title`}>Salary Details ($)</h4>
                    </div>
                    <div className={`col-lg-6`}>
                        <Input
                            type="number"
                            defaultValue={hunt?.min_salary}
                            className={` uk-input`}
                            name="min_salary"
                            styleType="floating"
                            placeholder="Minimum Salary"
                            onKeyDown={(e) => {
                                if (["+", "-", "e", "."]?.includes(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                        />
                        {errors["min_salary"] && <Error marginTop="-12px" error={errors["min_salary"]} />}
                    </div>
                    <div className={`col-lg-6`}>
                        <Input
                            type="number"
                            defaultValue={hunt?.max_salary}
                            className={`uk-input`}
                            name="max_salary"
                            styleType="floating"
                            placeholder="Maximum Salary"
                            onKeyDown={(e) => {
                                if (["+", "-", "e", "."]?.includes(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                        />
                        {errors["max_salary"] && <Error marginTop="-12px" error={errors["max_salary"]} />}
                    </div>
                </div>

                <fieldset className={`row`}>
                    <div className={`mb-4 col-lg-12`}>
                        <div className={`form-group1 mt-30`}>
                            {cover || validateImage == 0 ? (
                                <div style={{ position: "relative" }}>
                                    <Button type="link" style={{ position: "absolute", right: "-5px", top: "-14px", fontSize: "1.5rem" }} onClick={removeImage}>
                                        <small
                                            style={{
                                                background: "#17a2b8",
                                                color: "white",
                                                padding: "3px",
                                                fontSize: "10px",
                                            }}
                                        >
                                            remove
                                        </small>
                                    </Button>
                                    <SquareImage className={`img-thumbnail`} src={imageSrc} width="100%" height="250px" />
                                </div>
                            ) : (
                                <>
                                    <h4 className={`main-title`}>Upload Image</h4>
                                    <div className={`${styles["image-upload-wrap"]} image-upload-wrap`}>
                                        <input onChange={onCoverUpload} type="file" accept="image/*" className={`file-upload-input`} />
                                        <div className={`drag-text`}>
                                            <i className={`icofont-cloud-upload`}></i>
                                            <h4>Upload Image</h4>
                                        </div>
                                    </div>
                                    {errors["image"] && <Error error={errors["image"]} />}
                                </>
                            )}
                        </div>
                    </div>
                    <div className={`mb-0 col-lg-12 text-center`}>
                        <Button ref={postBtn} htmlType="submit" type="button" className={`form__button`}>
                            {hunt_id ? "Update" : "Create"} Hunt
                        </Button>
                        <Progress style={{ margin: "15px" }} ref={progressRef} />
                    </div>
                </fieldset>
            </form>
        </div>
    );
});
const newHuntSchema = Joi.object({
    validate_image: Joi.number().required(),
    job_type_id: Joi.number()
        .required()
        .messages({
            "number.base": validateRequired("Job type"),
        }),

    pay_type_id: Joi.number()
        .required()
        .messages({
            "number.base": validateRequired("Pay type"),
        }),

    business: Joi.string()
        .required()
        .messages({
            "string.empty": validateRequired("Business name"),
        }),

    job_title: Joi.string()
        .required()
        .messages({
            "string.empty": validateRequired("Job title"),
        }),

    job_description: Joi.string().allow(null, ""),

    min_salary: Joi.number()
        .min(100)
        .max(1000000)
        .required()
        .messages({
            "number.base": validateRequired("Min salary"),
            "number.min": "Min. salary should be at least 100",
            "number.max": "Min. salary should be less than 1,000000",
        }),

    max_salary: Joi.number()
        .min(100)
        .max(1000000)
        .required()
        .messages({
            "number.base": validateRequired("Max salary"),
            "number.min": "Max. salary should be at least 100",
            "number.max": "Max. salary should be less than 1,000,000",
        }),

    image: Joi.when("validate_image", {
        is: 1,
        then: Joi.object()
            .required()
            .messages({
                "any.required": validateRequired("Image"),
                "object.base": validateRequired("Image"),
            }),
        otherwise: Joi.any().forbidden(),
    }),

    status: Joi.string()
        .required()
        .messages({
            "string.empty": validateRequired("Status"),
        }),

    region: Joi.string().required(),
    location_name: Joi.string().required(),
    latitude: Joi.string().required(),
    longitude: Joi.string().required(),
    interest_id: Joi.number()
        .required()
        .messages({
            "number.base": validateRequired("Category"),
        }),
});

export default CreateHunt;
