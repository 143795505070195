import React, { useRef } from "react";
import "./floating-select.style.css";
const SelectDropdown = React.forwardRef((props, ref) => {
    const { styleType, type, placeholder, prependText, disabled, readOnly, options } = props;
    const selectRef = useRef();

    const handleSelect = (event) => {
        props.onChange(event.target.value);
    };

    const renderOptions = () => {
        return options.map((o, i) => {
            return (
                <option key={o.i} value={o.id} selected={props.selected == o.id}>
                    {o.name}
                </option>
            );
        });
    };
    // const label = "testing label";

    switch (styleType) {
        case "simple":
            return (
                <select className="form-control" onChange={handleSelect}>
                    {renderOptions()}
                </select>
            );
        case "floating":
            return (
                <label className="pure-material-textfield-outlined pure-selectfield">
                    <select name={props.name} className={`${props.className}`} placeholder=" " onChange={handleSelect}>
                        {renderOptions()}
                    </select>
                    <span>{props.placeholder}</span>
                    <small className="text-info">{prependText}</small>
                </label>
            );
        default:
            return <select className="form-control"></select>;
    }
});

SelectDropdown.defaultProps = {
    className: "",
    type: "text",
    placeholder: "",
};

export default SelectDropdown;
